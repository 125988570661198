import AdvStack from "@components/layout/stack/stack";
import AdvStackItem from "@components/layout/stack/stack-item/stack-item";
import { useLanguages } from "@hooks/language/useLanguages";
import useAdvComponent from "@hooks/useAdvComponent";
import { deepCompareJSXProps } from "@utils/deep-compare";
import { getCode, getName } from "country-list";
import { countryToAlpha2, countryToAlpha3 } from "country-to-iso";
import Image from "next/image";
import React, { useMemo } from "react";
import AdvDropdown, { TAdvDropdownItem, TAdvDropdownProps } from "../dropdown";
import * as flags from "./flags";

export type TAdvLanguageDropdownProps = Omit<TAdvDropdownProps, "options" | "label" | "value"> & {
    label?: string;
};

const Flag = ({ code, style }: { code: string | undefined; style?: React.CSSProperties }) => {
    if (Object.keys(flags as any).includes(code?.toLowerCase() ?? ""))
        return (
            <Image
                src={(flags as any)[code?.toLowerCase() ?? "de"]}
                alt={"-"}
                width={40}
                height={20}
                style={style}
            />
        );

    return <></>;
};

export const renderLanguageItem = (optParam: any) => {
    const isArr = Array.isArray(optParam);
    const opt = isArr ? optParam[0] : optParam;
    if (opt != undefined) {
        return (
            <AdvStack
                horizontal
                styles={{ root: { maxHeight: 40 } }}
                verticalAlign="center"
                verticalFill
                tokens={{ childrenGap: 10 }}
            >
                <AdvStackItem verticalFill shrink={0}>
                    <AdvStack verticalAlign="center" verticalFill>
                        <Flag
                            code={getCode(
                                getName(countryToAlpha2(opt.data) ?? "") ??
                                    getName(countryToAlpha3(opt.data) ?? "") ??
                                    "",
                            )}
                        ></Flag>
                    </AdvStack>
                </AdvStackItem>
                <AdvStackItem>{opt.text}</AdvStackItem>
            </AdvStack>
        );
    }
    return <></>;
};

const AdvLanguageDropdownComp = ({
    label = "Sprache wählen",
    ...props
}: TAdvLanguageDropdownProps) => {
    useAdvComponent(AdvLanguageDropdownComp, props);

    const { languages, currentLanguage, setCurrentLanguageById, browserLanguage } = useLanguages();
    const items: TAdvDropdownItem[] = useMemo(() => {
        return languages.map((lang) => {
            const text =
                browserLanguage !== undefined && browserLanguage.ID == lang.ID
                    ? `${lang.Language} (default)`
                    : lang.Language;

            return {
                key: `langdd_${lang.ID}`,
                text,
                data: lang.ISO.substring(lang.ISO.indexOf("_") + 1),
            } as TAdvDropdownItem;
        });
    }, [browserLanguage, languages]);

    const handleValueChanged = (newValue: string | TAdvDropdownItem | undefined): undefined => {
        if (newValue === undefined) return;

        const key = typeof newValue == "string" ? newValue : newValue.key;
        const id = typeof key == "number" ? key : Number(key.replace("langdd_", ""));
        setCurrentLanguageById(id);
    };

    return (
        <AdvDropdown
            label={label}
            options={items}
            value={`langdd_${currentLanguage.ID}`}
            onValueChanged={handleValueChanged}
            onRenderOption={renderLanguageItem}
            onRenderTitle={renderLanguageItem}
            styles={{ root: { minWidth: 50, width: 210, maxWidth: 210 } }}
            {...props}
        />
    );
};

const AdvLanguageDropdown = React.memo(AdvLanguageDropdownComp, deepCompareJSXProps);
export default AdvLanguageDropdown;
